import { Box, Flex, Text } from "rebass/styled-components";
import Trans from "next-translate/Trans";
import Button from "./Button";
import { useConsent } from "./ConsentModeContext";
import Heading from "./Heading";
import Link from "./Link";

const ConsentModeBanner = () => {
  const { consentMode, accept, reject } = useConsent();

  if (consentMode) return <></>;

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 100,
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "white",
        px: 6,
        py: 3,
        borderTop: "1px solid lightgray"
      }}
    >
      <Heading as="p" mb={1}>
        <Trans i18nKey="common:consentModeTitle" />
      </Heading>

      <Box>
        <Text
          mb={2}
          as="p"
          sx={{
            color: "labradorBlack",
            "a:-webkit-any-link": {
              color: "springGreen"
            }
          }}
        >
          <Trans
            i18nKey="common:consentModeDesc"
            components={{
              1: <Link href="/terms/privacy_policy" target="_blank" />
            }}
          />
        </Text>
      </Box>

      <Flex>
        <Button mr={2} onClick={accept}>
          <Trans i18nKey="common:consentModeAccept" />
        </Button>
        <Button variant="terciary" onClick={reject}>
          <Trans i18nKey="common:consentModeReject" />
        </Button>
      </Flex>
    </Box>
  );
};

export default ConsentModeBanner;
