import TagManager from "./react-gtm";
import {
  gtmProductName,
  gtmProductId,
  gtmProductBrand,
  gtmProductCategory,
  gtmProductVariant,
  gtmProductQuantity,
  gtmProductPrice,
  gtmCurrencyCode
} from "./gtm-enhanced-ecommerce";

import { GetCaregiverQuery } from "../generated/graphql";

type GudogFrontEndEvent =  // GA4
  | "route_change_complete"
  | "owner_sitter_searched"
  | "owner_sitter_profile_visited"
  | "owner_sitter_contact_intended"
  | "user_signed_up"
  | "owner_registration_completed"
  | "owner_booking_request_sent"
  | "owner_3x_booking_requests_visited"
  | "owner_3x_booking_requests_sent"
  | "owner_booking_payment_method_added"
  | "owner_booking_paid"
  | "owner_booking_payment_3ds_requested"
  | "owner_booking_payment_3ds_completed"
  | "owner_booking_payment_3ds_failed"
  | "owner_download_app_intended"
  | "owner_booking_request_intended"
  | "user_signed_in"
  | "user_sign_in_failed"
  | "user_message_sent"
  | "sitter_calendar_updated"
  | "owner_job_publication_card_showed"
  | "owner_job_publication_visited"
  | "owner_job_publication_created"
  | "owner_job_application_visited"
  | "owner_job_application_accepted"
  | "owner_job_application_declined"
  | "user_app_download_ios_intent"
  | "user_app_download_android_intent"
  | "consent_mode_accepted"
  | "consent_mode_rejected";

// Tracks a normal event
function trackEvent(
  eventName: GudogFrontEndEvent,
  eventParams?: any,
  ecommerce?: any
): void {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      eventParams,
      ecommerce
    }
  });
}

// Add user property to data layer so that it can be read by GTM
function setUserProperty(name, value): void {
  TagManager.dataLayer({
    dataLayer: {
      [name]: value
    }
  });
}

function trackOwnerSitterProfileVisited(
  caregiver: NonNullable<GetCaregiverQuery["caregiver"]>,
  variant?: string
): void {
  trackEvent(
    "owner_sitter_profile_visited",
    {
      sitterId: caregiver.id,
      ...(variant ? { variant: variant } : {})
    },
    {
      detail: {
        products: [
          {
            name: gtmProductName(caregiver),
            id: gtmProductId(caregiver),
            brand: gtmProductBrand()
          }
        ]
      }
    }
  );
}

function trackOwnerBookingRequestSent(booking: any, variant?: string): void {
  trackEvent(
    "owner_booking_request_sent",
    {
      sitterId: booking.caregiver.id,
      bookingId: booking.id,
      ...(variant ? { variant: variant } : {})
    },
    {
      currencyCode: gtmCurrencyCode(booking),
      checkout: {
        actionField: { step: 2 },
        products: [
          {
            name: gtmProductName(booking.caregiver),
            id: gtmProductId(booking.caregiver),
            brand: gtmProductBrand(),
            category: gtmProductCategory(booking),
            variant: gtmProductVariant(booking),
            quantity: gtmProductQuantity(booking),
            price: gtmProductPrice(booking)
          }
        ]
      }
    }
  );
}

function trackOwnerSitterSearched(
  searchInputVariables: any,
  searchResults: any,
  variant?: string
): void {
  trackEvent("owner_sitter_searched", {
    ...searchInputVariables,
    filters: Object.keys(searchInputVariables),
    firstDate:
      searchInputVariables.startDate ||
      searchInputVariables.serviceDates?.split("/")[0],
    results: searchResults.caregivers.length,
    totalResults: searchResults.totalEntries,
    totalPages: searchResults.totalPages,
    ...(variant ? { variant: variant } : {})
  });
}

export {
  trackEvent,
  setUserProperty,
  trackOwnerSitterProfileVisited,
  trackOwnerBookingRequestSent,
  trackOwnerSitterSearched
};
