import gql from "graphql-tag";

export const CurrentUserFragment = gql`
  fragment CurrentUserParts on CurrentUser {
    id
    name
    surname
    email
    type
    avatar {
      circle
    }
    canCreateABillingProfile
    billingProfile {
      id
      identityStatus
    }
    intercomUserHash
  }
`;

export const BookingFragment = gql`
  fragment BookingParts on Booking {
    id
    fromDogley
    userId
    caregiverId
    canBeTransferredToBankAccount
    availableEvents
    serviceDates
    startDate
    endDate
    serviceQuantity
    createdAt
    updatedAt
    serviceDates
    status
    dogIds
    canCaregiverBeReviewed
    conversationId
    numberOfTrips
    meetAndGreet
    meetAndGreetPrice {
      amount
      currency
      toS
    }
    refundAmount {
      amount
      currency
      toS
    }
    transferAmount {
      amount
      currency
      toS
    }
    dogReviews {
      id
      content
      score
      reviewable {
        ... on Dog {
          id
          name
        }
      }
    }
    caregiverReview {
      id
      content
      score
      reviewable {
        ... on Caregiver {
          id
          name
        }
      }
    }
    bankWire {
      id
      createdAt
      total {
        amount
        currency
        toS
      }
    }
    total {
      amount
      currency
      toS
    }
    totalFee {
      amount
      currency
      toS
    }
    serviceTotal {
      amount
      currency
      toS
    }
    serviceType {
      id
      alwaysPickUpDropOff
      fullDayAvailability
      name
      pricePer
    }
    countryServiceType {
      name
    }
    caregiver {
      id
      name
      latitude
      longitude
      cleanHeadline
      publicAddress
      city {
        id
        name
        slug
      }
      avatar {
        circle
      }
      slug
    }
    user {
      id
      name
      city {
        id
        name
        slug
      }
      avatar {
        circle
      }
      dogs {
        id
        name
        breed {
          id
          name
          tName
        }
        avatar {
          circle
          big
        }
        size
        tAge
        birthday
        slug
        gender
      }
    }
    contact {
      surname
      phone
      address1
      address2
      zip
      latitude
      longitude
    }
    bookingDogs {
      id
      dogId
      bookingId
      price {
        toS
        amount
        currency
      }
      priceType
      canDogBeReviewed
      dog {
        id
        name
        avatar {
          circle
        }
        size
        gender
      }
    }
    dogs {
      id
      name
      avatar {
        circle
        big
      }
      size
      tAge
      birthday
      slug
    }
    pickUpDropOff
    pickUpDropOffPrice {
      toS
      amount
      currency
    }
    latePickUpPrice {
      amount
      currency
      toS  
    }
    meetAndGreet
    meetAndGreetPrice {
      amount
      currency
      toS
    }
    costAdjustment {
      toS
      amount
      currency
    }
    couponRedemption {
      id
      coupon {
        id
        code
        type
        fixedDiscount {
          toS
          amount
          currency
        }
        percentageDiscount
        minimumAmount {
          toS
          amount
          currency
        }
        currency
        startsAt
        expiresAt
      }
    }
    service {
      id
      price
      holidayPrice
      puppyPrice
      additionalDogPrice
      pickUpDropOffPrice
      meetAndGreetPrice
      meetAndGreet
      currency
    }
  }
`;

export const DogFragment = {
  profile: gql`
    fragment DogProfileParts on Dog {
      id
      name
      gender
      size
      slug
      birthday
      spayedNeutered
      chip
      playsDogs
      playsDogsDesc
      playsCats
      playsCatsDesc
      playsChildren
      playsChildrenDesc
      observations
      avatar {
        circle
        big
      }
      tGender
      tAge
      tAgeShort
      tSize
      breed {
        id
        name
        tName
      }
      photos {
        id
        miniThumb
        original
        slider
        thumb
        title
      }
    }
  `
};

export const ConversationFragment = gql`
  fragment ConversationParts on Conversation {
    id
    partner {
      name
      id
      avatar {
        circle
      }
      type
      ... on Caregiver {
        slug
        caregiverReviewsScore
        caregiverReviewsCount
        caregiverCompletedBookingsCount
        caregiverStatus
        caregiverActive
        services {
          id
        }
      }
    }
    bookings {
      requests {
        ...BookingParts
      }
    }
  }
  ${BookingFragment}
`;

export const ConversationSummaryFragment = gql`
  fragment ConversationSummaryParts on ConversationSummary {
    id
    partner {
      id
      name
      avatar {
        circle
      }
      dogs {
        name
      }
    }
    lastMessage {
      id
      createdAt
      content
      type
      booking {
        id
        user {
          id
        }
        caregiver {
          id
        }
      }
    }
    unreadMessages
    bookingHistory {
      requests
      upcoming
      past
    }
  }
`;

export const MessageFragment = gql`
  fragment MessageParts on PaginatedMessages {
    currentPage
    totalPages
    results {
      id
      content
      createdAt
      type
      attachment {
        big
        preview
        small
      }
      fromUser {
        id
        name
        avatar {
          circle
        }
      }
      booking {
        ...BookingParts
      }
    }
  }
  ${BookingFragment}
`;

export const ServiceFragment = gql`
  fragment ServiceParts on Service {
    id
    serviceTypeId
    currency
    price
    dogPrice
    catPrice
    holidayPrice
    holidayCatPrice
    puppyPrice
    additionalDogPrice
    additionalCatPrice
    extraTimePrice
    pickUpDropOff
    pickUpDropOffPrice
    pickUpDropOffDistance
    meetAndGreet
    meetAndGreetPrice
    acceptsDogs {
      giant
      large
      medium
      small
      puppy
    }
    unavailable
  }
`;

export const CaregiverFragment = {
  profile: gql`
    fragment CaregiverParts on CurrentCaregiver {
      id
      name
      cleanHeadline
      caregiverHeadline
      caregiverDescription
      caregiverXpAcommodatingDogs
      caregiverXpAsDogWalker
      caregiverProvideCustomerReviews
      caregiverOwnVehicle
      caregiverCanAdministerOralMeds
      caregiverCanAdministerInjectedMeds
      caregiverVolunteerAnimalGroups
      caregiverHasAnimalCertificate
      caregiverHasKidsUnder18
      caregiverHasPppLicense
      caregiverHasCats
      caregiverDeactivatedAt
      caregiverCalendarUpdatedAt
      caregiverStatus
      services {
        ...ServiceParts
      }
      avatar {
        profile
      }
      city {
        id
        name
        slug
      }
    }
    ${ServiceFragment}
  `,
  rates: gql`
    fragment CaregiverRatesParts on CurrentCaregiver {
      id
      isSuperCaregiver
      name
      services {
        ...ServiceParts
      }
    }
    ${ServiceFragment}
  `
};
