import React, { createContext, useState, useContext, useEffect } from "react";
import TagManager from "../../lib/react-gtm";
import { trackEvent } from "../../lib/event-tracking";

type ConsentMode = "granted" | "denied" | undefined;

type ConsentContextType = {
  consentMode: ConsentMode;
  accept: () => void;
  reject: () => void;
};

const STORAGE_KEY = "USER_CONSENT";

const ConsentContext = createContext<ConsentContextType | undefined>(undefined);

function getStoredConsent(): ConsentMode {
  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (stored === "granted" || stored === "denied") {
      return stored;
    }
    return undefined;
  } catch (error) {
    console.warn("Could not access localStorage:", error);
    return undefined;
  }
}

export function ConsentProvider({ children }: { children: React.ReactNode }) {
  const [consentMode, setConsentMode] = useState<ConsentMode>(() =>
    getStoredConsent()
  );

  const accept = () => setConsentMode("granted");
  const reject = () => setConsentMode("denied");

  // Persist to localStorage whenever consent changes
  useEffect(() => {
    if (consentMode) {
      try {
        localStorage.setItem(STORAGE_KEY, consentMode);
      } catch (error) {
        console.warn("Could not save to localStorage:", error);
      }
    }
  }, [consentMode]);

  // GTM notification
  useEffect(() => {
    if (consentMode) {
      if (consentMode === "granted") {
        setTimeout(() => {
          trackEvent("consent_mode_accepted");
        }, 1000); // wait for GTM to be loaded
      } else if (consentMode === "denied") {
        setTimeout(() => {
          trackEvent("consent_mode_rejected");
        }, 1000); // wait for GTM to be loaded
      }
    }
  }, [consentMode]);

  return (
    <ConsentContext.Provider
      value={{
        consentMode,
        accept,
        reject
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
}

export function useConsent() {
  const context = useContext(ConsentContext);

  if (!context) {
    throw new Error("useConsent must be used within ConsentProvider");
  }

  return context;
}
